import React, { useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

//Function Imports

//Component Imports
import { TwoCols, TwoCol, Section } from '../components/page-elements';
import SEO from '../components/seo';
import Container from '../components/container';
import PrimaryHeaderBlock from '../components/primaryHeaderBlock';
import PhotoCollage from '../components/photoCollage';
import FourPointBlock from '../components/fourPointBlock';
import BottomCut from '../components/bottomCut';
import HalfScreenVideo from '../components/halfScreenVideo';
import PageTextBlock from '../components/pageTextBlock';
import CenteredTextBlock from '../components/centeredTextBlock';
import VideoGrid from '../components/videoGrid';
import VideoCarousel from '../components/video-carousel/videoCarousel';
import Spacer from '../components/spacer';
import TestimonialCarousel from '../components/testimonialCarousel';
import LogoGrid from '../components/logoGrid';
import CTABlock from '../components/ctaBlock';
import ImageStripe from '../components/imageStripe';
import BreathingPlayButton from '../components/breathingPlayButton';
import ScrollToTopButton from '../components/scrollToTopButton';
import ScrollDownPrompt from '../components/scrollDownPrompt';
import VideoModal from '../components/videoModal';

const HeroPlayButton = styled(BreathingPlayButton)`
  top: 28.5%;
  left: 45.75%;
`;

const ModalWrapper = styled.div`
  /* Take it out of document flow */
  position: absolute;
  /* Just to overwrite the modal video plugins styles */
  .modal-video-movie-wrap {
    background: none;
  }

  .modal-video {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-video-close-btn {
    cursor: pointer;
  }
`;

const ProductPage = ({ data }) => {
  const { sanityProductPage, allSanityIndustryPage } = data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setIsModalOpen((prevState) => !prevState),
    [],
  );

  return (
    <>
      <SEO title={`${sanityProductPage.productTag.label}s`} />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <ScrollToTopButton />
      <ScrollDownPrompt />
      <Section
        light
        style={{
          paddingBottom: 0,
          marginTop: 'var(--site-header-height)',
          overflowX: 'clip',
        }}
      >
        <Container>
          <TwoCols>
            <TwoCol centerVertically>
              <PrimaryHeaderBlock data={sanityProductPage.hero} />
            </TwoCol>
            <TwoCol centerVertically>
              <PhotoCollage photos={sanityProductPage.heroPhotos} layout={2}>
                <HeroPlayButton onClick={toggleModal} />
                <VideoModal
                  isModalOpen={isModalOpen}
                  video={sanityProductPage.featuredVideo}
                  onClose={() => setIsModalOpen(false)}
                />
              </PhotoCollage>
            </TwoCol>
          </TwoCols>
        </Container>
      </Section>
      <Section light cuts='extSectBottomCut'>
        <Spacer size={48} />
        <FourPointBlock data={sanityProductPage.keyBenefits} />
        <BottomCut dark />
      </Section>
      <Section dark cuts='topCut' style={{ overflowX: 'clip' }}>
        <Container>
          <TwoCols reverse>
            <TwoCol>
              <PageTextBlock data={sanityProductPage.marketingBlock1} />
            </TwoCol>
            <TwoCol>
              <HalfScreenVideo
                data={sanityProductPage.midPageVideo}
                position={'left'}
              />
            </TwoCol>
          </TwoCols>
        </Container>
      </Section>
      <Section dark cuts='extSectBottomCut' style={{ overflowX: 'clip' }}>
        <Container>
          <TwoCols>
            <TwoCol centerVertically>
              <PageTextBlock data={sanityProductPage.marketingBlock2} />
            </TwoCol>
            <TwoCol centerVertically>
              <PhotoCollage
                photos={sanityProductPage.midPagePhotos}
                videoLoop={sanityProductPage.collageVideoLoop}
                layout={3}
                threshold={0.1}
              />
            </TwoCol>
          </TwoCols>
        </Container>
        <BottomCut />
      </Section>
      <Section cuts='topAndBottomCut'>
        <Container>
          <CenteredTextBlock data={sanityProductPage.portfolio} />
          <Spacer size={64} />
        </Container>
        {sanityProductPage.featuredWork.length < 7 ? (
          <Container>
            <VideoGrid videos={sanityProductPage.featuredWork} />
          </Container>
        ) : (
          <VideoCarousel
            videos={sanityProductPage.featuredWork}
            solutionRoutes={allSanityIndustryPage.nodes}
          />
        )}
        <BottomCut light />
      </Section>
      <Section light cuts='topCut'>
        {
          {
            testimonials: (
              <>
                <TestimonialCarousel
                  testimonials={sanityProductPage.testimonials}
                  hasLogoPagination={true}
                />
                <Spacer size={144} />
              </>
            ),
            logos: (
              <>
                <LogoGrid clients={sanityProductPage.clients} />
                <Spacer size={144} />
              </>
            ),
          }[sanityProductPage.socialProofType]
        }
        <Container>
          <CTABlock data={sanityProductPage.ctaBlock} />
        </Container>
      </Section>
      <ImageStripe
        light
        image={sanityProductPage.footerImage}
        centered={sanityProductPage.centeredFooterImage}
      />
    </>
  );
};

// GraphQL Query
export const portfolioPageQuery = graphql`
  query ($productLabel: String) {
    sanityProductPage(productTag: { label: { eq: $productLabel } }) {
      productTag {
        label
        slug {
          current
        }
      }
      hero {
        ...SanityPrimaryHeaderBlockFragment
      }
      heroPhotos {
        ...SanityPhotoCollageFragment
      }
      featuredVideo {
        ...SanityVideoFragment
      }
      keyBenefits {
        miniPoint1 {
          ...SanityMiniPointFragment
        }
        miniPoint2 {
          ...SanityMiniPointFragment
        }
        miniPoint3 {
          ...SanityMiniPointFragment
        }
        miniPoint4 {
          ...SanityMiniPointFragment
        }
      }
      marketingBlock1 {
        ...SanityPageTextBlockFragment
      }
      midPageVideo {
        ...SanityVideoLoopFragment
      }
      marketingBlock2 {
        ...SanityPageTextBlockFragment
      }
      midPagePhotos {
        ...SanityPhotoCollageFragment
      }
      collageVideoLoop {
        ...SanityVideoLoopFragment
      }
      portfolio {
        ...SanityCenteredTextBlockFragment
      }
      featuredWork {
        ...SanityVideoFragment
      }
      socialProofType
      testimonials {
        quoteHighlight
        quote
        author
        jobTitle
        client {
          company
          svgLogo
        }
        image {
          ...SanityImageFragment
        }
      }
      clients {
        company
        svgLogo
      }
      ctaBlock {
        ...SanityCTABlockFragment
      }
      footerImage {
        ...SanityImageFragment
      }
      centeredFooterImage
    }
    allSanityIndustryPage {
      nodes {
        industryTag {
          slug {
            current
          }
        }
      }
    }
  }
`;

export default ProductPage;
